@import "./variables.scss";
.Main{
    background-color: black;
    position: absolute;
    top: $altoCabezayPies;
    bottom: $altoCabezayPies;
    left: 0px;
    right: 0px;
    z-index: 101;
    color: white;
    @media #{$DesktopLandscape}{
        left: 300px;
        bottom: 0px;
    }
    &:focus{
        outline: none;
    }
    .MainNav{
        background-color: #00000071;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% - 25px);
        z-index: 200;
        user-select: none;
        @media #{$DesktopLandscape}{
            display: none;
        }
        &.next{
            right: 0px;
        }
        &.prev{
            left: 0px;
        }
        &:active{
            background-color: #50505096;
        }
    }
    .Dicom{
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 0;
        &:active{
            outline: none;
        }
        &[activo]::before{
            content: "";
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background-color: rgb(201, 228, 25);
            z-index: 100;
            opacity: 1;
            transition: 250ms;
            &.activo{
                opacity: 0.5;
            }
        }
    }
    .Etiquetas{
        position: absolute;
        max-width: 200px;
        padding: 10px;
        &.top{
            top: 0px;
        }
        &.bottom{
            bottom: 0px;
        }
        &.left{
            left: 0px;
        }
        &.right{
            right: 0px;
        }
        .Logo{
            width: 80px;
            height: 80px;
        }
        .LoadingImg{
            position: relative;
            .LoadingImgSpinner1{
                position: absolute;
                animation: spin 2s linear infinite;
            }
            .LoadingImgSpinner2{
                position: absolute;
                left: 10px;
            }
            .Texto{
                position: absolute;
                top: 30px;
            }
        }
        .Etiquetasbottomright{
            width: 50px;
            height: 50px;
            font-size: 25px;
            color: $color2;
        }
    }
    .PDF{
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 100;
        display: none;
        background-color: white;
        z-index: 210;
        &.activo{
            display: block;
        }
    }
    .Warning{
        $ancho:200px;
        position: absolute;
        width: $ancho;
        height: 0px;
        left: calc(50% - (#{$ancho}/2));
        bottom: 10px;
        z-index: 150;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        transition: 250ms;
        border-radius: 10px;
        $alto:40px;
        &.Peligro{
            background-color: #c63c2dc5;
            height: $alto;
        }
        &.Advertencia{
            background-color: #c6aa2dc5;
            height: $alto;
        }
        &.Notificasion{
            background-color: #2dc63ac5;
            height: $alto;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }